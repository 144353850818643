.customDotPlacement {
  bottom: -3rem;
}

.customItem {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
